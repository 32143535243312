@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;500&display=swap');

.home{
    background-color: #E5E6EE;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    
  }
.home-container{
    width: 62%;
    height: 60%;
    margin-top: -100px;
    border-radius: 25px;
    background-image: linear-gradient(to left,white 95%,#2F80ED 5%);
    border: 1px solid #2F80ED;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #333333;
    box-sizing: border-box;
}
.name{
    font-family: 'BPG Nino Mtavruli';
    font-size: 30px;
    margin-bottom: 20px;
}
.full-name{
    font-family: 'BPG Nino Mtavruli';
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
}
.alert{
    font-size: 45px;
    margin-bottom: 40px;
}
.site-name{
    font-family: 'Antonio';
    font-size: 90px;
}

/* ----------------------- Responsive part  -------------- */
@media only screen and (max-width:800px){
    .home{
        background-color: #E5E6EE;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        
      }
    .home-container{
        width: 95%;
        height: 40%;
        margin-top: -50px;
        border-radius: 25px;
    }
    .name{
        font-size: 15px;
        margin-bottom: 20px;
    }
    .full-name{
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 25px;
    }
    .alert{
        font-size: 20px;
        margin-bottom: 20px;
    }
    .site-name{
        font-size: 45px;
    }
    .info{
        text-align: center;
        font-size: 14px;
    }    
}
